
import Carrers from './Components/Carrers';
// import Enquiry from './Components/Enquiry';
import './Components/Enquiry.css'

function App() {
  return (
    <div>
      {/* <h1>Welcome</h1> */}
      {/* <Enquiry /> */}
      <Carrers/>
    </div>
  );
}

export default App;
