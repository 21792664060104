import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Carrers() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="text-center">Join The Team</h3>
          </div>
          <div>
            <p className="text-center">
              If you are intresting in applying.Please complete this form,
              attach your resume & submit.
            </p>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Name"
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Email ID"
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Your Contact"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <select id="application" name="application" className="form-select">
              <option value="Application For">Application For</option>
              <option value="Freelance Lead Generator">
                Freelance Lead Generator/Consultant-Business Developer
              </option>
              <option value="Business Development Officer">
                Business Development Officer
              </option>
              <option value="Application Software Developer">
                Application Software Developer
              </option>
              <option value="Team Leader">
                Assistant Team Leader/Team Leader
              </option>
              <option value="Android Developer">Android Developer</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
